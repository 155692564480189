/* CODE GEN GUARD START: Please do not edit code within guards */
// import generatedDetails from './generated/details.generated';
/* CODE GEN GUARD END: Please do not edit code within guards */

import React from 'react';
import { FormattedMessage } from 'react-intl';

import textClassificationBlocksIconURL from './text_classification/text-classification-blocks.png';
import textClassificationBlocksInsetIconURL from './text_classification/text-classification-blocks-small.svg';

import poseBodyBlocksIconURL from './posenet/pose-body.png';
import poseHandBlocksIconURL from './posenet/pose-hand.png';
import poseFaceBlocksIconURL from './posenet/pose-face.png';
import teachableMachineBlocksIconURL from './teachable-machine-blocks/teachable-machine-logo.png';
import teachableMachineBlocksInsetIconURL from './teachable-machine-blocks/teachable-machine-blocks-small.svg';

import arduinoRobotIconURL from './arduino_extension/arduino_extension.png';
import arduinoRobotInsetIconURL from './arduino_extension/arduino_small.svg';

import microbitRobotIconURL from './arduino_extension/microbot_extension.png';

import gizmoRobotIconURL from './arduino_extension/gizmo_extension.png';
import gizmoRobotInsetIconURL from './arduino_extension/gizmo_small.png';

import musicIconURL from './music/music.png';
import musicInsetIconURL from './music/music-small.svg';

import penIconURL from './pen/pen.png';
import penInsetIconURL from './pen/pen-small.svg';

import videoSensingIconURL from './videoSensing/video-sensing.png';
import videoSensingInsetIconURL from './videoSensing/video-sensing-small.svg';

// import posenetIconURL from './posenet/video-sensing.png';
import poseHandInsetIconURL from './posenet/pose-hand-small-3.svg';
import poseFaceInsetIconURL from './posenet/pose-face-small.svg';
import poseBodyInsetIconURL from './posenet/pose-body-small.svg';

import text2speechIconURL from './text2speech/text2speech.png';
import text2speechInsetIconURL from './text2speech/text2speech-small.svg';

import translateIconURL from './translate/translate.png';
import translateInsetIconURL from './translate/translate-small.png';

import makeymakeyIconURL from './makeymakey/makeymakey.png';
import makeymakeyInsetIconURL from './makeymakey/makeymakey-small.svg';

import microbitIconURL from './microbit/microbit.png';
import microbitInsetIconURL from './microbit/microbit-small.svg';
import microbitConnectionIconURL from './microbit/microbit-illustration.svg';
import microbitConnectionSmallIconURL from './microbit/microbit-small.svg';

import intelinoIconURL from './intelino/intelino.png';
import intelino2IconURL from './intelino/intelino2.png';
import intelino3IconURL from './intelino/intelino3.png';
import intelino4IconURL from './intelino/intelino4.png';
import intelinoInsetIconURL from './intelino/intelino-small.svg';
import intelinoConnectionIconURL from './intelino/intelino-illustration.svg';
import intelinoConnectionSmallIconURL from './intelino/intelino-small.svg';
import intelinoInsetRedIconURL from './intelino/intelino-small-red.svg';
import intelinoConnectionRedIconURL from './intelino/intelino-illustration-red.svg';
import intelinoConnectionSmallRedIconURL from './intelino/intelino-small-red.svg';
import intelinoInsetYellowIconURL from './intelino/intelino-small-yellow.svg';
import intelinoConnectionYellowIconURL from './intelino/intelino-illustration-yellow.svg';
import intelinoConnectionSmallYellowIconURL from './intelino/intelino-small-yellow.svg';
import intelinoInsetGreenIconURL from './intelino/intelino-small-green.svg';
import intelinoConnectionGreenIconURL from './intelino/intelino-illustration-green.svg';
import intelinoConnectionSmallGreenIconURL from './intelino/intelino-small-green.svg';

import ev3IconURL from './ev3/ev3.png';
import ev3InsetIconURL from './ev3/ev3-small.svg';
import ev3ConnectionIconURL from './ev3/ev3-hub-illustration.svg';
import ev3ConnectionSmallIconURL from './ev3/ev3-small.svg';

import wedo2IconURL from './wedo2/wedo.png'; // TODO: Rename file names to match variable/prop names?
import wedo2InsetIconURL from './wedo2/wedo-small.svg';
import wedo2ConnectionIconURL from './wedo2/wedo-illustration.svg';
import wedo2ConnectionSmallIconURL from './wedo2/wedo-small.svg';
import wedo2ConnectionTipIconURL from './wedo2/wedo-button-illustration.svg';

import boostIconURL from './boost/boost.png';
import boostInsetIconURL from './boost/boost-small.svg';
import boostConnectionIconURL from './boost/boost-illustration.svg';
import boostConnectionSmallIconURL from './boost/boost-small.svg';
import boostConnectionTipIconURL from './boost/boost-button-illustration.svg';

import gdxforIconURL from './gdxfor/gdxfor.png';
import gdxforInsetIconURL from './gdxfor/gdxfor-small.svg';
import gdxforConnectionIconURL from './gdxfor/gdxfor-illustration.svg';
import gdxforConnectionSmallIconURL from './gdxfor/gdxfor-small.svg';

export default [
    /* CODE GEN GUARD START: Please do not edit code within guards */
    // ...generatedDetails,
    /* CODE GEN GUARD END: Please do not edit code within guards */
    {
        name: 'intelino smart train 1',
        extensionId: 'intelino',
        collaborator: 'intelino',
        iconURL: intelinoIconURL,
        insetIconURL: intelinoInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Control the train's movement and actions on the track."
                description="Description for the 'intelino smart train' extension"
                id="gui.extension.intelino.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: intelinoConnectionIconURL,
        connectionSmallIconURL: intelinoConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their intelino Smart Train."
                id="gui.extension.intelino.connectingMessage"
            />
        ),
        helpLink: 'https://support.intelino.com/category/32-programming-with-scratch'
    },
    {
        name: 'intelino smart train 2',
        extensionId: 'intelino2',
        collaborator: 'intelino',
        iconURL: intelino2IconURL,
        insetIconURL: intelinoInsetRedIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Enable interactivity between two smart trains."
                description="Description for the secondary 'intelino smart train' extension"
                id="gui.extension.intelino.description2"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: intelinoConnectionRedIconURL,
        connectionSmallIconURL: intelinoConnectionSmallRedIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their intelino Smart Train."
                id="gui.extension.intelino.connectingMessage"
            />
        ),
        helpLink: 'https://support.intelino.com/category/32-programming-with-scratch'
    },
    {
        name: 'intelino smart train 3',
        extensionId: 'intelino3',
        collaborator: 'intelino',
        iconURL: intelino3IconURL,
        insetIconURL: intelinoInsetYellowIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Enable interactive programming with three smart trains."
                description="Description for the tertiary 'intelino smart train' extension"
                id="gui.extension.intelino.description3"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: intelinoConnectionYellowIconURL,
        connectionSmallIconURL: intelinoConnectionSmallYellowIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their intelino Smart Train."
                id="gui.extension.intelino.connectingMessage"
            />
        ),
        helpLink: 'https://support.intelino.com/category/32-programming-with-scratch'
    },
    {
        name: 'intelino smart train 4',
        extensionId: 'intelino4',
        collaborator: 'intelino',
        iconURL: intelino4IconURL,
        insetIconURL: intelinoInsetGreenIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Enable interactive programming with four smart trains."
                description="Description for the quaternary 'intelino smart train' extension"
                id="gui.extension.intelino.description4"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: intelinoConnectionGreenIconURL,
        connectionSmallIconURL: intelinoConnectionSmallGreenIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their intelino Smart Train."
                id="gui.extension.intelino.connectingMessage"
            />
        ),
        helpLink: 'https://support.intelino.com/category/32-programming-with-scratch'
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Teachable Machine"
                description="Teachable Machine name"
                id="gui.extension.teachable-machine.name"
            />
        ),
        extensionId: 'teachableMachine',
        iconURL: teachableMachineBlocksIconURL,
        insetIconURL: teachableMachineBlocksInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Use your Teachable Machine models in your Scratch project!"
                description="Teachable Machine Description"
                id="gui.extension.teachable-machine.description"
            />
        ),
        featured: true
    },
    /*
    {
        name: (
            <FormattedMessage
                defaultMessage="Text Classification"
                description="Text Classification name"
                id="gui.extension.text-classification.name"
            />
        ),
        extensionId: 'textClassification',
        iconURL: textClassificationBlocksIconURL,
        insetIconURL: textClassificationBlocksInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Create a text classification model for use in a Scratch project!"
                description="Text Classification Description"
                id="gui.extension.text-classification.description"
            />
        ),
        featured: true
    },
    */
    {
        name: "Hand Sensing",
        extensionId: 'poseHand',
        iconURL: poseHandBlocksIconURL,
        insetIconURL: poseHandInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense hand movement with the camera."
                description="PoseNet Description"
                id="gui.extension.pose_hand.description"
            />
        ),
        featured: true
    },
    {
        name: "Face Sensing",
        extensionId: 'poseFace',
        iconURL: poseFaceBlocksIconURL,
        insetIconURL: poseFaceInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense face movement with the camera."
                description="PoseNet Description"
                id="gui.extension.pose_face.description"
            />
        ),
        featured: true
    },
    {
        name: "Body Sensing",
        extensionId: 'poseBody',
        iconURL: poseBodyBlocksIconURL,
        insetIconURL: poseBodyInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense body position with the camera."
                description="PoseNet Description"
                id="gui.extension.pose_body.description"
            />
        ),
        featured: true
    },
    /*
    {
         name: (
            <FormattedMessage
                defaultMessage="PRG Microbit Robot"
                description="Name for the 'Microbit Robot' Extension"
                id="gui.extension.microbit-robot.name"
            />
        ),
        extensionId: 'microbitRobot',
        iconURL: microbitRobotIconURL,
        insetIconURL: microbitInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Use Scratch to program a Microbit smart car robot"
                description="PRG Microbit Robot description"
                id="gui.extension.microbit-robot.description"
            />
        ),
        featured: true
    },
    {
         name: (
            <FormattedMessage
                defaultMessage="PRG Gizmo Robot"
                description="Name for the 'Gizmo Robot' Extension"
                id="gui.extension.gizmo-robot.name"
            />
        ),
        extensionId: 'gizmoRobot',
        iconURL: gizmoRobotIconURL,
        insetIconURL: gizmoRobotInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Use Scratch to program the Gizmo robot"
                description="PRG Gizmo Robot description"
                id="gui.extension.gizmo-robot.description"
            />
        ),
        featured: true
    },{
         name: (
            <FormattedMessage
                defaultMessage="PRG Arduino Robot"
                description="Name for the 'Arduino Robot' Extension"
                id="gui.extension.arduino-robot.name"
            />
        ),
        extensionId: 'arduinoRobot',
        iconURL: arduinoRobotIconURL,
        insetIconURL: arduinoRobotInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Use Scratch to program an Arduino smart car robot"
                description="PRG Arduino Robot Car description"
                id="gui.extension.arduino-robot.description"
            />
        ),
        featured: true
    }, //*/ // RANDI Removing extensions I don't want students to play with
    {
        name: (
            <FormattedMessage
                defaultMessage="Music"
                description="Name for the 'Music' extension"
                id="gui.extension.music.name"
            />
        ),
        extensionId: 'music',
        iconURL: musicIconURL,
        insetIconURL: musicInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Play instruments and drums."
                description="Description for the 'Music' extension"
                id="gui.extension.music.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Pen"
                description="Name for the 'Pen' extension"
                id="gui.extension.pen.name"
            />
        ),
        extensionId: 'pen',
        iconURL: penIconURL,
        insetIconURL: penInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Draw with your sprites."
                description="Description for the 'Pen' extension"
                id="gui.extension.pen.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Video Sensing"
                description="Name for the 'Video Sensing' extension"
                id="gui.extension.videosensing.name"
            />
        ),
        extensionId: 'videoSensing',
        iconURL: videoSensingIconURL,
        insetIconURL: videoSensingInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense motion with the camera."
                description="Description for the 'Video Sensing' extension"
                id="gui.extension.videosensing.description"
            />
        ),
        featured: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Text to Speech"
                description="Name for the Text to Speech extension"
                id="gui.extension.text2speech.name"
            />
        ),
        extensionId: 'text2speech',
        collaborator: 'Amazon Web Services',
        iconURL: text2speechIconURL,
        insetIconURL: text2speechInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make your projects talk."
                description="Description for the Text to speech extension"
                id="gui.extension.text2speech.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: (
            <FormattedMessage
                defaultMessage="Translate"
                description="Name for the Translate extension"
                id="gui.extension.translate.name"
            />
        ),
        extensionId: 'translate',
        collaborator: 'Google',
        iconURL: translateIconURL,
        insetIconURL: translateInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Translate text into many languages."
                description="Description for the Translate extension"
                id="gui.extension.translate.description"
            />
        ),
        featured: true,
        internetConnectionRequired: true
    },
    {
        name: 'Makey Makey',
        extensionId: 'makeymakey',
        collaborator: 'JoyLabz',
        iconURL: makeymakeyIconURL,
        insetIconURL: makeymakeyInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Make anything into a key."
                description="Description for the 'Makey Makey' extension"
                id="gui.extension.makeymakey.description"
            />
        ),
        featured: true
    },
    {
        name: 'micro:bit',
        extensionId: 'microbit',
        collaborator: 'micro:bit',
        iconURL: microbitIconURL,
        insetIconURL: microbitInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Connect your projects with the world."
                description="Description for the 'micro:bit' extension"
                id="gui.extension.microbit.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: microbitConnectionIconURL,
        connectionSmallIconURL: microbitConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their micro:bit."
                id="gui.extension.microbit.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/microbit'
    },
    {
        name: 'LEGO MINDSTORMS EV3',
        extensionId: 'ev3',
        collaborator: 'LEGO',
        iconURL: ev3IconURL,
        insetIconURL: ev3InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build interactive robots and more."
                description="Description for the 'LEGO MINDSTORMS EV3' extension"
                id="gui.extension.ev3.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: ev3ConnectionIconURL,
        connectionSmallIconURL: ev3ConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting. Make sure the pin on your EV3 is set to 1234."
                description="Message to help people connect to their EV3. Must note the PIN should be 1234."
                id="gui.extension.ev3.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/ev3'
    },
    {
        name: 'LEGO BOOST',
        extensionId: 'boost',
        collaborator: 'LEGO',
        iconURL: boostIconURL,
        insetIconURL: boostInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Bring robotic creations to life."
                description="Description for the 'LEGO BOOST' extension"
                id="gui.extension.boost.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: boostConnectionIconURL,
        connectionSmallIconURL: boostConnectionSmallIconURL,
        connectionTipIconURL: boostConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their BOOST."
                id="gui.extension.boost.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/boost'
    },
    {
        name: 'LEGO Education WeDo 2.0',
        extensionId: 'wedo2',
        collaborator: 'LEGO',
        iconURL: wedo2IconURL,
        insetIconURL: wedo2InsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Build with motors and sensors."
                description="Description for the 'LEGO WeDo 2.0' extension"
                id="gui.extension.wedo2.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: true,
        connectionIconURL: wedo2ConnectionIconURL,
        connectionSmallIconURL: wedo2ConnectionSmallIconURL,
        connectionTipIconURL: wedo2ConnectionTipIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their WeDo."
                id="gui.extension.wedo2.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/wedo'
    },
    {
        name: 'Go Direct Force & Acceleration',
        extensionId: 'gdxfor',
        collaborator: 'Vernier',
        iconURL: gdxforIconURL,
        insetIconURL: gdxforInsetIconURL,
        description: (
            <FormattedMessage
                defaultMessage="Sense push, pull, motion, and spin."
                description="Description for the Vernier Go Direct Force and Acceleration sensor extension"
                id="gui.extension.gdxfor.description"
            />
        ),
        featured: true,
        disabled: false,
        bluetoothRequired: true,
        internetConnectionRequired: true,
        launchPeripheralConnectionFlow: true,
        useAutoScan: false,
        connectionIconURL: gdxforConnectionIconURL,
        connectionSmallIconURL: gdxforConnectionSmallIconURL,
        connectingMessage: (
            <FormattedMessage
                defaultMessage="Connecting"
                description="Message to help people connect to their force and acceleration sensor."
                id="gui.extension.gdxfor.connectingMessage"
            />
        ),
        helpLink: 'https://scratch.mit.edu/vernier'
    },
];
