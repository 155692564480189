exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .dial_container_3hox- {\n    padding: 1rem;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-pack: center;\n    -webkit-justify-content: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n} .dial_dial-container_ktOnA {\n    position: relative;\n} .dial_dial-face_3NuSA, .dial_dial-handle_1tfZT, .dial_gauge_3g5NZ {\n    position: absolute;\n    top: 0;\n    left: 0;\n    overflow: visible;\n} .dial_dial-face_3NuSA {\n    width: 100%;\n} .dial_dial-handle_1tfZT {\n    cursor: pointer;\n    width: 40px;\n    height: 40px;\n    /* Use margin to make positioning via top/left easier */\n    margin-left: calc(40px / -2);\n    margin-top: calc(40px / -2);\n} .dial_gauge-path_1WoJ6 {\n    fill: hsla(195, 100%, 42%, 0.35);\n    stroke: hsla(195, 100%, 42%, 1);\n    stroke-width: 1px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "dial_container_3hox-",
	"dial-container": "dial_dial-container_ktOnA",
	"dialContainer": "dial_dial-container_ktOnA",
	"dial-face": "dial_dial-face_3NuSA",
	"dialFace": "dial_dial-face_3NuSA",
	"dial-handle": "dial_dial-handle_1tfZT",
	"dialHandle": "dial_dial-handle_1tfZT",
	"gauge": "dial_gauge_3g5NZ",
	"gauge-path": "dial_gauge-path_1WoJ6",
	"gaugePath": "dial_gauge-path_1WoJ6"
};